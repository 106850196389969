<script lang="ts">
import { defineComponent } from 'vue'
import { storeToRefs } from 'pinia'
import { useStore } from '../store'

export default defineComponent({
  name: 'BreadcrumbComponent',
  setup() {
    const store = useStore()
    const { categories, campaign, tipsAndAdvice, tipsAndAdviceCategory, products, brands } =
      storeToRefs(store)
    return { categories, campaign, tipsAndAdvice, tipsAndAdviceCategory, products, brands }
  },
  computed: {
    category() {
      return this.categories[this.$route.params.id as string]
    },
    getTipsAndAdvice() {
      return this.tipsAndAdvice[this.$route.params.slug as string]
    },
    getTipsAndAdviceCategory() {
      return this.tipsAndAdviceCategory[this.$route.params.slug as string]
    },
    product() {
      return this.products[this.$route.params.id as string]
    },
    search() {
      return this.$route.query.q as string
    }
  }
})
</script>

<template>
  <div class="breadcrumb-wrapper">
    <ol class="breadcrumb" :class="{ 'breadcrumb-product': product }">
      <template v-if="$route.name == 'Tips & råd - Arkiv'">
        <li class="breadcrumb-item">
          <router-link to="/">Djur &amp; Natur</router-link>
        </li>
        <li class="breadcrumb-item current">Tips &amp; råd</li>
      </template>
      <template v-if="$route.name == 'Tips & råd - Kategori'">
        <li class="breadcrumb-item">
          <router-link to="/">Djur &amp; Natur</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/tips-och-rad">Tips &amp; råd</router-link>
        </li>
        <li class="breadcrumb-item current">
          {{ getTipsAndAdviceCategory.name }}
        </li>
      </template>
      <template v-if="$route.name == 'Tips & råd'">
        <li class="breadcrumb-item">
          <router-link to="/">Djur &amp; Natur</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/tips-och-rad">Tips &amp; råd</router-link>
        </li>
        <li class="breadcrumb-item current">
          {{ getTipsAndAdvice.name }}
        </li>
      </template>
      <template v-if="$route.name == 'Kampanj'">
        <li class="breadcrumb-item">
          <router-link to="/">Djur &amp; Natur</router-link>
        </li>
        <li class="breadcrumb-item">Kampanj</li>
        <li class="breadcrumb-item current">
          {{ campaign.name }}
        </li>
      </template>
      <template v-if="$route.name == 'Produkter' || $route.name == 'Kategori' || product || search">
        <li
          class="breadcrumb-item"
          :style="{
            'margin-right':
              product ||
              ($route.name == 'Kategori' && category && category.ancestor_tree.length >= 1)
                ? '-5px'
                : ''
          }"
        >
          <router-link :to="{ name: 'Produkter' }">
            {{ $route.name == 'Produkter' || search ? 'Alla produkter' : 'Kategori' }}
          </router-link>
        </li>
      </template>
      <template v-if="$route.name == 'Brand'">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'Brands' }">Varumärke</router-link>
        </li>
        <template v-for="brand in brands">
          <li
            v-if="$route.params.brand == brand.slug"
            :key="brand.id"
            class="breadcrumb-item capitalize"
          >
            {{ brand.name }}
          </li>
        </template>
      </template>

      <template v-if="category">
        <template v-if="category.ancestor_tree">
          <li
            v-for="ancestor in category.ancestor_tree"
            :key="ancestor.id"
            class="breadcrumb-item ancestor"
          >
            <router-link :to="{ name: 'Kategori', params: { id: ancestor.slug } }">
              {{ ancestor.name }}
            </router-link>
          </li>
        </template>
        <li class="breadcrumb-item active">
          <router-link :to="{ name: 'Kategori', params: { id: category.slug } }">
            {{ category.name }}
          </router-link>
        </li>
      </template>
      <template v-if="product">
        <li v-for="cat in product.category_tree" :key="cat.id" class="breadcrumb-item">
          <router-link :to="{ name: 'Kategori', params: { id: cat.slug } }">
            {{ cat.name }}
          </router-link>
        </li>
        <li class="breadcrumb-item current">{{ product.name }}</li>
      </template>
      <template v-if="search">
        <li class="breadcrumb-item current">Sök: {{ $route.query.q }}</li>
      </template>
    </ol>
  </div>
</template>

<style lang="scss">
@import '@/assets/scss/style.scss';
.breadcrumb-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
}
.breadcrumb {
  list-style-type: none;
  padding: 0;
  white-space: nowrap;
  &::before {
    content: '';
    width: 1rem;
    height: 1rem;
    position: absolute;
    left: 0;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 1) 100%);
  }
  @media (max-width: 1024px) {
    padding: 1px 1rem;
  }
  .breadcrumb-item {
    display: inline-block;
    color: $main;
    font-family: $inter;
    font-weight: 400;
    font-size: 0.8125rem;
    letter-spacing: -0.17px;

    &:not(:last-child)::after {
      content: '/';
      color: #9fbad8;
      margin: 0 15px;
    }
    &.active,
    &.current {
      margin-left: -5px;
    }
    &.current {
      font-family: $inter;
      font-weight: 600;
    }
    .router-link-active,
    a {
      color: $main;
    }
  }
}
.breadcrumb-product {
  margin: 1em 0;
  @media (min-width: 1024px) {
    margin: 1.5em 2em;
  }
}
.capitalize {
  text-transform: capitalize;
}
</style>
